<template>
    <div class="memberRecharge">
        <div class="left-box">
            <div class="title">会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input keyBoard type="text" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Bestech_Card_KindName"/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.MemberName" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Mobile" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Birthday" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Gender"/></div>
                </div>
                <div class="title">资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" readonly v-model="memberInfo.Card_Money"/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" readonly v-model="memberInfo.Card_PilePoint"/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="scroll-box">
                <div class="clearfix">
                    <div class="float-left">
                        <div class="title">充值列表</div>
                        <div class="recharge-box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.item-li',marginH:12,paddingH:12,isRow:false}">
                            <div v-if="memberInfo.MemberRechargePlan">
                                <div class="item-li" :class="{selected:item.Plan_DetailID==PlanDetail.Plan_DetailID}" v-for="item in memberInfo.MemberRechargePlan.RechargePlanDetail" :key="item" @click="couponClick(item)">
                                    <div v-show="item.Is_Diy_ChargeMoney==false">
                                        <div class="name nowrap" v-show="item.Recharge_Kind==0">充{{parseFloat(item.Recharge_Money)}}</div>
                                        <div class="name nowrap" v-show="item.Recharge_Kind==1">充{{parseFloat(item.Recharge_Money)}}送{{parseFloat(item.Gift_Money)}}元</div>
                                        <div class="name nowrap" v-show="item.Recharge_Kind==2">充{{parseFloat(item.Recharge_Money)}}<span v-if="parseFloat(item.Gift_Money)>0">送{{parseFloat(item.Gift_Money)}}%</span></div>
                                        <div class="describe nowrap">
                                            <span v-if="parseFloat(item.Gift_Money)>0">送{{parseFloat(item.Gift_Money)}}{{item.Recharge_Kind==1?'元':'%'}}储值，</span>
                                            <span v-if="parseFloat(item.Point_Num)>0">{{parseFloat(item.Point_Num)}}{{item.Point_Kind==1?'':'%'}}个积分</span>
                                            <span v-if="item.Number>0">和{{item.Number}}张优惠券！</span>
                                        </div>
                                        <i class="iconfont icon-gou1"></i>
                                    </div>
                                    <div v-show="item.Is_Diy_ChargeMoney==true">
                                        <div class="name nowrap">
                                            自定义充值金额
                                        </div>
                                        <div class="describe nowrap">
                                            <span>最大不超过{{parseFloat(item.Recharge_Money)}}</span>
                                        </div>
                                        <i class="iconfont icon-gou1"></i>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="bnt-right">
                                <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="float-right" v-show="PlanDetail.Is_Diy_ChargeMoney==false">
                        <div class="title">充值详情</div>
                        <div class="li" v-if="this.Details.title1">
                            <div class="number">1、</div><div class="describe">{{this.Details.title1}}</div>
                        </div>
                        <div class="li" v-if="this.Details.title2">
                            <div class="number">2、</div><div class="describe">{{this.Details.title2}}</div>
                        </div>
                        <div class="li" v-if="this.Details.title3">
                            <div class="number">3、</div><div class="describe">{{this.Details.title3}}</div>
                        </div>
                    </div>
                    <div class="float-right" v-show="PlanDetail.Is_Diy_ChargeMoney==true">
                        <div class="title">充值详情</div>
                        <div class="diyRecharge">
                            <div v-for="(item,index) in diyDetails" :key="index" :class="{diyType:index>=1}">
                                <div class="li">
                                    <div class="describe">{{item.title1}}</div><br />
                                </div>
                                <div class="li">
                                    <div class="describemoneys">{{item.name1}}</div>
                                </div>
                                <div class="li">
                                    <div class="describemoney">{{item.title2}}</div>
                                </div>
                                <div class="li">
                                    <div class="describemoneys">{{item.name2}}</div>
                                </div>
                                <div class="li" v-show="item.name3">
                                    <div class="describemoney">{{item.title3}}</div>
                                </div>
                                <div class="li" v-show="item.name3">
                                    <div class="describemoneys">{{item.name3}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-box">
                    <div class="title">卡充值确认信息</div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">充值金额：</div>
                            <div class="from-input">
                                <input type="text" keyBoard placeholder="请输入" v-model="rechargeMoney" :readonly="isInputEnter==1 && PlanDetail?.Is_Diy_ChargeMoney==false" oninput="value=value.replace(/[^\d.]/g,'')"  @focus="(e)=>e.target.select()"/>
                            </div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">赠送金额：</div>
                            <div class="from-input">
                                <input type="text" keyBoard placeholder="请输入" v-model="giftMoney" :readonly="isInputEnter==1 && isDiyReadonly==false" oninput="value=value.replace(/[^\d.]/g,'')" @focus="(e)=>e.target.select()"/>
                            </div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">对卡金额：</div>
                            <div class="from-input">
                                <input type="text" placeholder="请输入" v-model="checkCardMoney" readonly />
                            </div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">赠送积分：</div>
                            <div class="from-input">
                                <input type="text" keyBoard placeholder="请输入" v-model="pointNum" :readonly="isInputEnter==1 && isDiyReadonly==false" oninput="value=value.replace(/[^\d.]/g,'')"  @focus="(e)=>e.target.select()"/>
                            </div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">充值销售员：</div>
                            <div class="from-input input-icon">
                                <input type="text" placeholder="请输入" v-model="rechargeUser" maxlength="15"/>
                                <i class="iconfont icon-lianxiren" @click="cardOpenerShow=true"></i>
                            </div>
                        </div>
                        <div class="td-5">
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt"></div>
                        <div class="from-input">
                            <label><input type="checkbox" v-model="isInvoicing" />是否开票</label>
                        </div>
                    </div>
                    <div class="tr-li" v-show="isInvoicing">
                        <div class="td-5">
                            <div class="lable-txt">开票金额：</div>
                            <div class="from-input">
                                <input type="text" keyBoard placeholder="请输入" v-model="invoiceMoney" />
                            </div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">发票号码：</div>
                            <div class="from-input">
                                <input type="text" keyBoard placeholder="请输入" v-model="invoiceNo" />
                            </div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">备注：</div>
                        <div class="from-input">
                           <input type="text" maxlength="25" v-model="Remark" />
                        </div>
                    </div>
                    <div class="title">付款方式</div>
                    <div class="pay-box">
                        <div class="pay-scroll"  v-scroll-anime="{up:'pay-up',down:'pay-down',liClass:'.item-li',marginH:8,isRow:false}">
                            <div class="item-li"
                                :aria-disabled="!memberInfo.Bestech_Card_KindID" 
                                :class="{selected:item.PAY_ID==SelectPayType.PAY_ID}"
                                v-for="item in payList" :key="item" @click="payClick(item)">{{item.PAY_NAME}}<i class="iconfont icon-gou1"></i></div> 
                            <div class="item-li" style="visibility: hidden;"></div>
                        </div>
                        <div class="item-operation">
                            <div class="bnt-fangxiang" ref="pay-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="pay-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="footer-box">
                    <button class="btn" @click="back()">返回</button>
                    <button class="btn selected" @click="recordShow=true">查看充值记录</button>
                    <button class="btn selected" @click="confirmRechargeClick()">确定充值</button>
                </div>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo.Coupons"></ticket-model>
        </modal-load> 
        <!-- 充值记录 -->
        <modal-load :isShow="recordShow">
            <recharge-record-model :isShow="recordShow" @closeModel="recordShow=false" :crmOpenCard="5"></recharge-record-model>
        </modal-load> 
        <!--聚合支付-->
        <modal-load :isShow="scanCodeShow">
            <scanCodePay-model :isShow="scanCodeShow" :SelectPlanDetail="SelectPlanDetail" :Cardtype='1' :ispaySuccess="ispaySuccess" :CrmBizID="CrmBizID" @scanCodeconfirm="scanCodeconfirm" @scanCodecloseModel="scanCodecloseModel" ></scanCodePay-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
        <!-- 选择充值销售员 -->
        <modal-load :isShow="cardOpenerShow">
            <custodian-model :isShow="cardOpenerShow" title="选择充值销售员" @closeModel="cardOpenerShow=false" @custodianconfirm="custodianconfirm" ></custodian-model>
        </modal-load> 
        <!--押金支付-->
        <modal-load :isShow="preMoneyShow">
            <member-pre-money-model :isShow="preMoneyShow" :rechargeMoney="rechargeMoney" 
                @close="preMoneyShow=false" @confirm="preMoneyConfirm" />
        </modal-load> 
    </div>
</template>

<script>
import { custodianModel,ticketModel,rechargeRecordModel,scanCodePayModel,selectCardMemberInfo,memberPreMoneyModel } from '../model'
import { newGuid } from '/src/common/index.js'

export default {
    name:'memberRecharge',
    components:{
        custodianModel,
        ticketModel,
        rechargeRecordModel,
        scanCodePayModel,
        selectCardMemberInfo,
        memberPreMoneyModel
    },
    data(){
        return {
            /**获取用户*/
            userInfo:'',
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
             /**内卡号 */
            cardSNR:'',
            /**是否开票 */
            isInvoicing:false,
            /**券列表 */
            ticketShow:false,
            //充值销售员名称
            rechargeUser:"",
            /**选择充值销售员 弹出层 */
            cardOpenerShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**充值记录 */
            recordShow:false,
            /**聚合支付弹出层 */
            scanCodeShow:false,
            /** 付款方式数据*/
            payList:[],
            /**会员信息 */
            memberInfo:'',
            /**券数量 */
            couponsNum:'',
            Details:{
                title1:'',
                title2:'',
                title3:''
            },
             /**选中的支付方式 */
            SelectPayType:'',
            /**充值详情*/
            diyDetails:[],
            /**选择的券 */
            PlanDetail:'',
            /**充值金额 */
            rechargeMoney:'',
            /**赠送金额 */
            giftMoney:'',
            /**对卡金额 */
            checkCardMoney:'',
            /**赠送积分 */
            pointNum:'',
            /**是否可以输入 */
            isInputEnter:0,
            /**是否可以自定义赠送金额积分 */
            isDiyReadonly:true,
            /**开票金额 */
            invoiceMoney:'',
            /**发票号码 */
            invoiceNo:'',
            /**会员业务流水ID, 如开卡支付的传 OpenCard_BizID 的值, 会员充值支付的传Recharge_BizID的值 */
            CrmBizID:'',
            /**是否付款成功 */
            ispaySuccess:0,
            SelectPlanDetail:'',
            /**自定义最大充值金额 */
            maxRechargeMoney:'',
            isContinTrig:0,//是否连续触发
            //押金支付 弹层
            preMoneyShow:false,
            /**充值备注 */
            Remark:""
        }
    },
    mounted(){
        this.loadPayList();
    },
    watch:{
        rechargeMoney(){
            if(Number(this.rechargeMoney)> Number(this.maxRechargeMoney) && Number(this.rechargeMoney)!=0){
                this.$message.warning('不能超过自定义金额');
                this.rechargeMoney='',this.giftMoney='',this.pointNum=''
                return 
            }
            if(this.isDiyReadonly){
                if(this.PlanDetail.Is_DiyInterval==0){
                    this.giftMoney = Number(this.rechargeMoney * (Number(this.PlanDetail.Gift_Money) / 100)).toFixed(2)
                    this.pointNum = Number(this.rechargeMoney * (Number(this.PlanDetail.Point_Num) / 100)).toFixed(0)
                }else{
                    if(this.PlanDetail.Is_DiyInterval_Range==0){
                        this.PlanDetail.RechargeDiyIntervals?.map(item=>{
                            if(Number(this.rechargeMoney) >=Number(item.Begin_Money) && Number(this.rechargeMoney)<=Number(item.End_Money)){
                                this.giftMoney = Number(this.rechargeMoney * (Number(item.GiftMoney_Percent) / 100)).toFixed(2)
                                this.pointNum = Number(this.rechargeMoney * (Number(item.GiftPoint_Percent) / 100)).toFixed(0)
                            }
                        })
                    }
                }
            }
            //是否为自定义充值金额
            if(this.PlanDetail?.Is_Diy_ChargeMoney){
                //开启了自定义赠送
                if(this.PlanDetail.Is_DiyInterval==1){
                    if(this.PlanDetail?.RechargeDiyIntervals){
                        this.giftMoney=0;
                        this.pointNum=0;
                        this.PlanDetail?.RechargeDiyIntervals.map(re=>{
                            if(Number(this.rechargeMoney)>Number(re.Begin_Money) && Number(this.rechargeMoney)<=Number(re.End_Money)){
                                this.giftMoney = Number(this.rechargeMoney * (Number(re.GiftMoney_Percent) / 100)).toFixed(2)
                                this.pointNum = Number(this.rechargeMoney * (Number(re.GiftPoint_Percent) / 100)).toFixed(0)
                            }
                        })
                    }
                }
            }
            let _cheMoney = Number(this.rechargeMoney)+Number(this.giftMoney)
            if(Number(_cheMoney)>0){
                this.checkCardMoney = Number(_cheMoney).toFixed(2)
            }else{
                this.checkCardMoney=0;
            }
            
        },
        giftMoney(){
            let _cheMoney = Number(this.rechargeMoney)+Number(this.giftMoney)
            if(Number(_cheMoney)>0){
                this.checkCardMoney = Number(_cheMoney).toFixed(2)
            }else{
                this.checkCardMoney=0;
            }
        },
        cardNo(){
            this.memberInfo='';
        }
    },
    methods:{
        /**退出 */
        back(){
            if(this.$route.params.Desk_AutoID){
                this.$router.push({name: 'diningDetail', params:{Desk_AutoID:this.$route.params.Desk_AutoID,Eat_AutoID:this.$route.params.Eat_AutoID,urlType:0}});
            }else{
                this.$emit("close");
            }
            return
        },
        /**加载 付款方式*/
        loadPayList(){
            this.userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetMemberPayments",{}).then((d)=>{
                if(d.ResponseHeader.ResultCode==0){
                    this.payList=d.ResponseBody||[];
                }else{
                    this.payList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.payList=[];
                this.$message.error('加载付款方式数据失败：'+e);
                console.log('加载付款方式数据失败：'+e);
            })
        },
        /**充值点击 */
        payClick(item){
            this.SelectPayType = item
        },
         /**点击查询图标读卡 */
        selectReadCard(){
            if(!this.cardNo && !this.cardSNR){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetRechargePlan:true, //获取会员的充值方案
            }).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    this.cardSNR=''
                    this.card_AutoID=''
                    // this.memberInfo = data.ResponseBody[0]
                    // if(this.memberInfo.Gender==0){
                    //     this.memberInfo.Gender='男'
                    // }else{
                    //     this.memberInfo.Gender='女'
                    // }
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.selectReadCard()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                if(this.memberInfo.Gender==0){
                    this.memberInfo.Gender='男'
                }else{
                    this.memberInfo.Gender='女'
                }
                if(data.Coupons){
                    this.couponsNum = data.Coupons.length
                }
                if(this.memberInfo.MemberRechargePlan){
                    if(this.memberInfo.MemberRechargePlan.RechargePlanDetail){
                        this.memberInfo.MemberRechargePlan.RechargePlanDetail.map(re=>{
                            let Numbers=0
                            if(re.RechargeReturnCoupons){
                                re.RechargeReturnCoupons.map(cou=>{
                                    Numbers += cou.Number
                                })
                            }
                            re = Object.assign(re, {Number:Numbers})
                        })
                    }
                }else{
                    this.$message.error('没有适用该卡的充值方案,请先去CRM配置相对应的方案');
                }
            })
        },
        ticketClick(){
            if(this.memberInfo!=''){
                this.ticketShow = true
            }
        },
        /**选择充值的项 */
        couponClick(item){
            this.diyDetails=[]
            this.maxRechargeMoney = item.Recharge_Money
            this.PlanDetail = item
            this.isInputEnter=0
            this.Details={title1:'',title2:'',title3:''}
            //是否为自定义充值金额true:是 false:不是
            if(item.Is_Diy_ChargeMoney==true){
                this.rechargeMoney='',this.giftMoney='',this.pointNum='',this.checkCardMoney=''
                //区间赠送 0关闭 1开启
                if(item.Is_DiyInterval==0){
                    this.isDiyReadonly=false
                    this.isInputEnter=1
                    if(parseFloat(item.Gift_Money)>0 || parseFloat(item.Point_Num)>0){
                         let _diy={
                            title1:'赠送储值:',
                            name1:parseFloat(item.Gift_Money)+'%元',
                            title2:'赠送积分:',
                            name2:parseFloat(item.Point_Num)+'%个'
                        }
                        this.diyDetails.push(_diy)
                    }
                }else{
                    this.isInputEnter=1;
                    this.isDiyReadonly=false;
                    //Is_DiyInterval_Range 0.固定比例 1.范围浮动
                    if(item.Is_DiyInterval_Range==0){
                        //this.isDiyReadonly=false
                        if(item?.RechargeDiyIntervals){
                            item.RechargeDiyIntervals.map(diy=>{
                                let _diy={
                                    title1:'充值金额范围:',
                                    name1:parseFloat(diy.Begin_Money)+'＜～＜＝'+parseFloat(diy.End_Money),
                                    title2:'赠送储值:',
                                    name2:parseFloat(diy.GiftMoney_Percent)+'%元',
                                    title3:'赠送积分:',
                                    name3:parseFloat(diy.GiftPoint_Percent)+'%个'
                                }
                                this.diyDetails.push(_diy)
                            })
                        }
                    }else{
                        this.isDiyReadonly=true
                        this.isInputEnter=0
                        if(item?.RechargeDiyIntervals){
                            item.RechargeDiyIntervals.map(diy=>{
                                let unit='元',pointunit='个'
                                //DiyInterval_RangeType 自定义金额区间赠送比例浮动范围类型 0：按百分比浮动 ; 1：按金额浮动
                                if(item.DiyInterval_RangeType==0){
                                    unit='%'
                                }
                                if(item.DiyInterval_RangeType==0){
                                    pointunit='%'
                                }
                                let _diy={
                                    title1:'充值金额范围:',
                                    name1:parseFloat(diy.Begin_Money)+'＜～＜＝'+parseFloat(diy.End_Money),
                                    title2:'赠送储值范围:',
                                    name2:parseFloat(diy.RangeMoney_Min)+unit+'～'+parseFloat(diy.RangeMoney_Max)+unit,
                                    title3:'赠送积分范围:',
                                    name3:parseFloat(diy.RangePoint_Min)+pointunit+'～'+parseFloat(diy.RangePoint_Max)+pointunit
                                }
                                if(parseFloat(diy.RangePoint_Min)==0 && parseFloat(diy.RangePoint_Max)==0){
                                    _diy.name3=''
                                }
                                this.diyDetails.push(_diy)
                            })
                        }
                    }
                }
                //this.Details.title1='充值金额'
            }else{
                this.isInputEnter=1
                this.isDiyReadonly=false
                this.rechargeMoney = parseFloat(item.Recharge_Money);

                if(item.Recharge_Kind==2){//按充值金额%赠送
                    this.giftMoney = Number((this.rechargeMoney*parseFloat(item.Gift_Money/100)).toFixed(2));
                }else{
                    this.giftMoney = parseFloat(item.Gift_Money);
                }
                
                if(item.Point_Kind==2){//按充值金额%赠送 个
                    this.pointNum =  Number((this.rechargeMoney*parseFloat(item.Point_Num/100)).toFixed(0));
                }else{
                    this.pointNum = Number(item.Point_Num).toFixed(0);
                }


               
                this.Details.title1 = '充值'+parseFloat(item.Recharge_Money);
                if(item.Recharge_Kind==1){
                    this.Details.title1+=',赠送'+ parseFloat(item.Gift_Money)+'元;'
                }else if(item.Recharge_Kind==2){
                    this.Details.title1+=',赠送'+ parseFloat(item.Gift_Money)+'%;'
                }

                
                if(Number(item.Point_Num).toFixed(0)>0){
                    this.Details.title2 ='赠送'+parseFloat(item.Point_Num)+(item.Point_Kind==1?'':'%')+'个积分;'
                }
                if(item?.RechargeReturnCoupons){
                    let title = ''
                    item.RechargeReturnCoupons.map((re,index)=>{
                        if(index==item.RechargeReturnCoupons.length-1){
                            title+=re.Number+'张'+re.Coupon_Name+'。'
                        }else{
                            title+=re.Number+'张'+re.Coupon_Name+','
                        }
                    })
                    this.Details.title3='赠送'+title
                }
            }
        },
        /**确认充值 */
        confirmRechargeClick(data){
            if(this.isContinTrig!=0){
                this.$message.warning('请勿连续触发充值')
                return
            }else{
                this.isContinTrig=1
            }
            if(this.memberInfo=='' || this.memberInfo==undefined){
                this.isContinTrig=0
                this.$message.warning('请先获取会员卡数据')
                return
            }
            if(this.rechargeMoney==''|| this.rechargeMoney==undefined){
                this.isContinTrig=0
                this.$message.warning('需要充值的金额不能为0')
                return
            }
            if(!this.PlanDetail){
                this.isContinTrig=0
                this.$message.warning('请先选择对应的充值方案')
                return
            }
            /**自定义充值验证 */
            if(this.PlanDetail.Is_Diy_ChargeMoney){
                this.isContinTrig=0
                /**数量最多只允许小数点后4位 */
                let num = /^\d+(\.\d{1,4})?$/;
                if(this.rechargeMoney!=''){
                    if (!num.test(this.rechargeMoney)){
                        this.$message.warning('充值金额最多只允许小数点后4位');
                        return;
                    }
                }else{
                    this.$message.warning('充值金额不能为空');
                    return;
                }
                if(this.giftMoney!=''){
                    if (!num.test(this.giftMoney)){
                        this.$message.warning('赠送金额最多只允许小数点后4位');
                        return;
                    }
                }
                if(this.PlanDetail.RechargeDiyIntervals && this.PlanDetail.Is_DiyInterval_Range==1){
                    let isError=this.PlanDetail.RechargeDiyIntervals.some(item=>{
                        if(Number(this.rechargeMoney) >Number(item.Begin_Money) && Number(this.rechargeMoney)<=Number(item.End_Money)){
                            let RangeMoney_Min=Number(item.RangeMoney_Min);
                            let RangeMoney_Max=Number(item.RangeMoney_Max);
                            if(this.PlanDetail.DiyInterval_RangeType==0){//0：按百分比浮动 ; 1：按金额浮动
                                RangeMoney_Min=Number((this.rechargeMoney* (RangeMoney_Min/100)).toFixed(2));
                                RangeMoney_Max=Number((this.rechargeMoney* (RangeMoney_Max/100)).toFixed(2));
                            }

                            if(Number(this.giftMoney)< RangeMoney_Min|| Number(this.giftMoney)>RangeMoney_Max){
                                return true;
                            }
                        }
                        return false;
                    })
                    if(isError){
                        this.$message.warning('赠送金额超过可浮动的上下限');
                        this.giftMoney=''
                        return
                    }
                    isError=this.PlanDetail.RechargeDiyIntervals.some(item=>{
                        if(Number(this.rechargeMoney) >Number(item.Begin_Money) && Number(this.rechargeMoney)<=Number(item.End_Money)){
                            let RangePoint_Min=Number(item.RangePoint_Min);
                            let RangePoint_Max=Number(item.RangePoint_Max);
                            if(this.PlanDetail.DiyInterval_RangeType==0){//0：按百分比浮动 ; 1：按金额浮动
                                RangePoint_Min=Number((this.rechargeMoney* (RangePoint_Min/100)).toFixed(2));
                                RangePoint_Max=Number((this.rechargeMoney* (RangePoint_Max/100)).toFixed(2));
                            }
                            if(Number(this.pointNum)<RangePoint_Min || Number(this.pointNum)>RangePoint_Max){
                                return true;
                            }
                        }
                        return false;
                    })
                    if(isError){
                        this.$message.warning('赠送积分超过可浮动的上下限');
                        this.pointNum=''
                        return
                    }
                }
                
                
            }
            this.SelectPlanDetail={ Recharge_Money:Number(this.rechargeMoney)}
            if(this.SelectPayType=='' || this.SelectPayType=='undefined' ){
                this.isContinTrig=0
                this.$message.warning('请选择支付方式')
                return
            }
            if(this.SelectPayType.PAY_NAME=="聚合支付" && this.scanCodeShow==false){
                this.isContinTrig=0
                this.scanCodeShow=true
                return
            }else if(this.SelectPayType.INTERFACE_TYPE=='11' && !this.preMoneyShow){//预付金，押金
                this.isContinTrig=0
                this.preMoneyShow=true;
                return;
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID,  //用户id
                Card_SN:this.cardSNR,
                Card_No:this.cardNo,//卡号
                Member_Name:this.memberInfo.MemberName,//会员姓名
                Mobile:this.memberInfo.Mobile, //手机号
                Plan_ID:this.memberInfo.MemberRechargePlan.Plan_ID, //充值方案ID
                Plan_DetailID:this.PlanDetail?.Plan_DetailID,//充值方案明细ID
                Is_Diy_ChargeMoney:this.PlanDetail?.Is_Diy_ChargeMoney, //是否是自定义充值
                Recharge_Money:Number(this.rechargeMoney), //充值金额
                Recharge_GiftMoney:Number(this.giftMoney), //充值赠送金额
                Recharge_GiftPoint:Number(this.pointNum), //充值赠送积分
                Recharge_GiftCouponsNum: Number(this.PlanDetail?.Number), //充值赠送券张数
                Is_Invoice:this.isInvoicing,//是否开票
                //Cost_Money 成本金额
                Invoice_Money:Number(this.invoiceMoney),//开票金额
                Invoice_No:this.invoiceNo,//发票号码
                Operate_User:this.rechargeUser||this.userInfo?.Login_Name,//充值操作员
                Operate_PosName:this.userInfo?.Site_Name, //开卡站点
                Best_Pay_SerialNo:'', //请求支付流水号
                Recharge_PayType:this.SelectPayType.INTERFACE_TYPE,//付款方式类型
                Recharge_PayCode:this.SelectPayType.PAY_CODE,//付款方式编号
                Recharge_PayID:this.SelectPayType.PAY_ID,//充值支付方式ID
                Remark:this.Remark,//备注
            }
            Object.assign(param,data);
            const loading = this.$loading({
                text: "会员卡充值验证中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('param:',param)
            this.$httpAES.post("Bestech.CloudPos.CardRecharge",param).then((data)=>{
                loading.close();
                this.isContinTrig=0
                if(data.ResponseHeader.ResultCode==0){
                    this.CrmBizID = data.ResponseBody.Recharge_BizID;
                    this.ispaySuccess=0
                    if(this.scanCodeShow==true){
                        this.scanCodeShow=false;
                    }else if(this.preMoneyShow){
                        this.preMoneyShow=false;
                    }
                    this.CardRechargeSubmit(data);
                }else{
                    if(data.ResponseHeader.ResultCode>0 && this.scanCodeShow==true){
                        this.CrmBizID = data.ResponseBody?.Recharge_BizID
                        if(data.ResponseHeader.ResultCode==4003){
                            this.ispaySuccess=0
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else if(data.ResponseHeader.ResultCode==4001){
                            this.ispaySuccess=1
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            this.ispaySuccess=1
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }
                    }else{
                        this.ispaySuccess=0
                        this.reload()
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员充值验证失败：'+e);
                console.log('会员充值验证失败：'+e);
            })
        },
        /**会员充值提交 */
        CardRechargeSubmit(data){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID,  //用户id
                Card_No:this.cardNo,//卡号
                AppType:'CloudPos',
                CommonPayID:this.SelectPayType.PAY_CODE,
                Best_Pay_SerialNo:data.ResponseBody.Recharge_BizID,//请求流水号
                WX_Pay_SerialNo:data.ResponseBody.WX_Pay_MchID,//微信支付成功流水号
                Operate_User:this.rechargeUser||this.userInfo?.Login_Name, //充值操作员
                Operate_PosName:this.userInfo?.Site_Name //开卡站点
            }
            const loading = this.$loading({
                text: "会员卡充值提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CardRechargeSubmit",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody?.length>0){
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(data.ResponseBody);
                        },100);
                    }
                    this.$message.success("充值完成");
                    this.reload()
                }else{
                    this.$message.error("充值失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员充值失败：'+e);
                console.log('会员充值失败：'+e);
            })
        },
        /**押金支付返回 */
        preMoneyConfirm(param){
            this.confirmRechargeClick({
                DepositRechargePayments:param //押金单支付列表，选押金支付时必传
            });
        },
         /**聚合支付返回 */
        scanCodeconfirm(payCode){
            this.confirmRechargeClick({
                SsPay_QrCode:payCode, //聚合支付二维码信息
            })
        },
        /**聚合支付关闭 */
        scanCodecloseModel(type){
            //type:0 关闭聚合支付弹窗 1不可关闭 2关闭聚合支付弹窗并去执行OpenActualCardDirectSubmit方法
            if(type==0){
                this.ispaySuccess=0
                this.scanCodeShow=false
            }else if(type==5){
                this.ispaySuccess=0
                this.scanCodeShow=false
                this.reload()
            }else{
                this.ispaySuccess=1
            }
        },
        /*刷新当前页面 */
        reload(){
            this.$emit("reload");
        },
        /**开卡人返回 */
        custodianconfirm(seleUser){
            this.rechargeUser = seleUser.User_Name;
            this.cardOpenerShow = false
        }
    }
}
</script>

<style lang="scss">
@import "./memberRecharge.scss";
</style>